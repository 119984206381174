export enum OrgIdTypeEnum {
  BET9JA = 'bet9ja',
  YAJUEGO = 'yajuego',
  MOZZARTBET = 'mozzartbet',
  MOZZARTBET_SERBIA = 'mozzartbet-serbia',
  GLI = 'gli_certification',
  TEAPUESTO = 'teapuesto',
  TEAPUESTO_PERU = 'teapuesto-peru',
  SOCCERBET_SERBIA = 'soccerbet-serbia',
  MAYBETS_KENYA = 'maybets-kenya',
  MAMABET = 'mamabet',
  MOZZARTBET_CROATIA = 'mozzartbet-croatia',
  SPIN_AND_WIN = 'spin-and-win',
  BET9JA_SHOP_APP = 'bet9ja-shop-app',
  GAMING_HUB = 'gaming-hub',
}
